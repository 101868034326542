import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  checkTokenResetPassword,
  resetPassword
} from '../../redux/actions/authActions'

import { useHistory, useParams } from 'react-router-dom'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const SignUp = ({ ...props }) => {
  const history = useHistory()
  let { id } = useParams()

  const [isSuccess, setIsSuccess] = useState(false)
  const [messageSuccess, setMessageSuccess] = useState(null)
  const [tokenStatus, setTokenStatus] = useState(false)
  const [tokenMessage, setTokenMessage] = useState(null)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({})

  const onSubmit = data => {
    resetPassword(id, data).then(res => {
      setMessageSuccess(res.message)
      setIsSuccess(res.success)
    })
  }

  useEffect(() => {
    const { auth } = props

    const isAuthenticated = auth.isAuthenticated

    if (isAuthenticated) {
      history.push('/start')
    } else {
      checkTokenResetPassword(id).then(res => {
        setTokenStatus(res.success)
        setTokenMessage(res.message)
      })
    }
  }, [])

  return (
    <>
      <div className='utility-page-wrap'>
        <div
          data-w-id='5e86ada79942c1e4247fd4c700000000000b'
          style={{
            transform:
              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            opacity: 1,
            transformStyle: 'preserve-3d'
          }}
          className='utility-page-content-password w-password-page w-form'
        >
          <div className='container-default'>
            <div className=''>
              <img
                src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecda3ef4028e0abe32b01b8_icon-password-jobs-template.svg'
                alt=''
                className='icon-password'
              />
            </div>
            <h2> {isSuccess ? messageSuccess : tokenMessage}</h2>
          </div>

          {!isSuccess && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='utility-page-form w-password-page'
            >
              {tokenStatus && (
                <>
                  {' '}
                  <input
                    type='password'
                    autofocus='true'
                    {...register('password', {
                      required: 'Please enter your password',
                      minLength: {
                        value: 6,
                        message: 'Minimum is length is 6'
                      }
                    })}
                    placeholder='Enter your password'
                    className='input password w-password-page w-input'
                  />
                  {errors.password && (
                    <h3 className='error__invalid'>
                      {errors.password.message}
                    </h3>
                  )}
                </>
              )}
              {tokenStatus && !isSuccess && (
                <button
                  type='submit'
                  className='button-primary full-width w-password-page w-button'
                >
                  Reset password
                </button>
              )}
            </form>
          )}

          <Link to='/login' variant='body2'>
            <div className='margin-top-2'>Already have an account? Sign in</div>
          </Link>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = state => ({
  auth: state.authentication
})

export default connect(mapStateToProps, {})(SignUp)
