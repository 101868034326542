const skills = [
  'FedRAMP',
 'Java',
 'CCSP',
 'Exploit',
 'OSCP',
 'NIST',
 'Risk management',
 'Rust',
 'CIPP',
 'SOC2',
 'Big Data',
 'Node.js',
 'GDPR',
 'GCED',
 'Scala',
 'Security Clearance',
 'Finance',
 'Penetration testing',
 'Python',
 'Vulnerability scans',
 'BurpSuite',
 'PaaS',
 'DNS',
 'Artificial intelligence',
 'Threat detection',
 'Kubernetes',
 'Go',
 'SDLC',
 'SSCP',
 'IPS',
 'Regulatory compliance',
 'Exploits',
 'PostMan',
 'ISO27001',
 'CRISC',
 'Open Source',
 'SecOps',
 'Pen testing',
 'Industrial',
 'DevSecOps',
 'CSIRT',
 'Burp Suite',
 'Petrochemical',
 'CTF',
 'CEH',
 'Blockchain',
 'Polygraph',
 'PCI-DSS',
 'Firewall',
 'Incident response',
 'Threat intelligence',
 'PKI',
 'Azure',
 'Forensics',
 'CISA',
 'ICS',
 'Windows',
 'PowerShell',
 'IPtables',
 'Intrusion detection',
 'Nessus',
 'CREST',
 'Intrusion prevention',
 'PHP',
 'GCP',
 'Redis',
 'TypeScript',
 'ISO 27001',
 'Red team',
 'Unix',
 'Cyber defense',
 'SANS',
 'AWS',
 'Crypto',
 'Risk assessment',
 'GCIH',
 'TCP/IP',
 'Auditing',
 'Splunk',
 'Perl',
 'CISSP',
 'Active Directory',
 'OWASP',
 'Ruby',
 'MacOS',
 'Linux',
 'Audits',
 'JavaScript',
 'SOC 2',
 'Lambda',
 'Analytics',
 'HIPAA',
 'Android',
 'CompTIA',
 'PCI',
 'Clearance',
 'Encryption',
 'DevOps',
 'SIEM',
 'Security assessments',
 'IaaS',
 'Malware',
 'Strategy',
 'Machine Learning',
 'GSEC',
 'Automation',
 'CISM',
 'Network security',
 'DoD',
 'C',
 'IDS',
 'Vulnerabilities',
 'iOS',
 'SaaS',
 'GCFA',
 'Docker',
 'Vulnerability management'
];
export default skills;
