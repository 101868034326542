export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_CURRENT_PROFILE = "GET_CURRENT_PROFILE";
export const GET_MISSING_INFORMATION_PROFILE =
  "GET_MISSING_INFORMATION_PROFILE";
export const EMPTY_MISSING_INFORMATION_PROFILE =
  "EMPTY_MISSING_INFORMATION_PROFILE";
export const ADD_OFFER_TO_CART = "ADD_OFFER_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";
export const GET_CREDIT_BALANCE = "GET_CREDIT_BALANCE";
export const UPDATE_SEARCH_TEXT = "UPDATE_SEARCH_TEXT";
export const EMPTY_SEARCH_TEXT = "EMPTY_SEARCH_TEXT";
export const ADD_PREFERENCES = "ADD_PREFERENCES";
