import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createFreeNewsletter } from '../../utils/api'
import { useHistory } from 'react-router-dom'

const Start = ({ ...props }) => {
  const history = useHistory()
  const { startingPrice, trialDays } = props

  const handleCreateFreeNewsletter = () => {
    createFreeNewsletter().then(res => {
      if (res.success) {
        history.push('/profile/me')
      }
    })
  }
  return (
    <div className='section price-tables wf-section'>
      <div className='container-small-580 text-center'>
        <h1
          data-w-id='6c093ffa-04f3-252d-5b43-bd29fb57fe07'
          style={{
            transform:
              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            opacity: 1,
            transformStyle: 'preserve-3d'
          }}
        >
          Get started
          <span className='color-primary-1 underline' />
        </h1>
        <p
          data-w-id='e394d5f5-68c4-b227-1580-6d1529e52ff2'
          style={{ opacity: 1 }}
          className='paragraph-large'
        >
          Looking for a new job opportunity? Select a plan to be matched with
          recruiters and receive your newsletter.
        </p>
      </div>
      <div className='container-medium'>
        <div className='w-layout-grid price-tables-grid'>
          <div
            data-w-id='503a9076-2180-28fd-751c-73692a2b6e33'
            style={{ opacity: 1 }}
            className='price-table-card featured'
          >
            <h2 className='color-white'>PRO</h2>
            <p className='color-white'>
              For candidates actively looking for new job opportunities.
            </p>
            <div className='price featured'>${startingPrice}</div>
            <p className='uppercase-small'>Starting Monthly Price</p>
            <div className='job-card-info-bottom'>
              <div class='featured-badge'>Cancel anytime</div>
              <a className='job-badge card margin-top-1'>
                {trialDays > 0 && `Free for ${trialDays} days`}
              </a>
            </div>

            <div className='divider price-table-featured' />
            <div className='w-layout-grid advantage-grid'>
              <div className='flex'>
                <img
                  src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc09382791ad4197644fb1_icon-featured-price-table-jobs-template.svg'
                  alt=''
                />
                <div className='advantage featured margin-left-1'>
                  Create a free profile for recruiters
                </div>
              </div>

              <div className='flex'>
                <img
                  src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc09382791ad4197644fb1_icon-featured-price-table-jobs-template.svg'
                  alt=''
                />
                <div className='advantage featured margin-left-1'>
                  Personalized newsletter with job offers based on your skills,
                  job title and location
                </div>
              </div>
              <div className='flex'>
                <img
                  src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc09382791ad4197644fb1_icon-featured-price-table-jobs-template.svg'
                  alt=''
                />
                <div className='advantage featured margin-left-1'>
                  Interview questions on Monday and answers on Tuesday
                </div>
              </div>
              {/* 
              
               <div className='flex'>
                <img
                  src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc09382791ad4197644fb1_icon-featured-price-table-jobs-template.svg'
                  alt=''
                />
                <div className='advantage featured margin-left-1'>
                  Unlimited applications
                </div>
              </div>
              */}
            </div>
            <Link to='/plans'>
              {' '}
              <a className='button-secondary large featured-full-width w-button'>
                Continue
              </a>
            </Link>
          </div>
          <div
            data-w-id='e78aa453-70b9-cca9-ab03-6757770c06cf'
            style={{ opacity: 1 }}
            className='price-table-card'
          >
            <h2>Free</h2>
            <p>
              For passive candidates but still interested in new opportunities.
            </p>
            <div className='price'>$0</div>
            <div className='divider price-table' />
            <div className='w-layout-grid advantage-grid'>
              <div className='flex'>
                <img
                  src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc077ae9133b088448aad0_icon-free-price-table-jobs-template.svg'
                  alt=''
                />

                <div className='advantage margin-left-1'>
                  Create a free profile for recruiters
                </div>
              </div>

              {/* 
              
              <div className='flex'>
                <img
                  src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc077ae9133b088448aad0_icon-free-price-table-jobs-template.svg'
                  alt=''
                />

                <div className='advantage margin-left-1'>
                  {' '}
                  Limited applications per month
                </div>
              </div>
              */}
            </div>
            <a
              onClick={handleCreateFreeNewsletter}
              className='button-primary large full-width w-button'
            >
              Continue
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  auth: state.authentication
})

export default connect(mapStateToProps, {})(Start)
