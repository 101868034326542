import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const prod = process.env.NODE_ENV === "production";

if (prod) {
  ReactGA.initialize("UA-167530009-3"); // add your tracking id here.
  ReactGA.pageview(window.location.pathname + window.location.search);
  hotjar.initialize("XXXXXXX", "6");
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
