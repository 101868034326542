import React, { useEffect, useState } from 'react'
import SignUpElementsForm from './PaymentFormCandidate'
import Layout from './Layout'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import { getCurrentPrices, getMyNewsletter } from '../../utils/api'
import DynamicScrollToTop from '../../utils/DynamicScrollToTop'
import isEmpty from '../../validation/is-empty'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

const PaymentForm = ({ ...props }) => {
  const { auth, newsletterData, selectedPlan } = props
  const user = auth.user
  
  return (
    <>
      <div>
        <DynamicScrollToTop />
        <Layout title='Payment with Checkout '>
          <div className='section wf-section'>
            <div className='container-default'>
              <div className='container-pricing'>
                <h1 class='text-align-center margin-top-5 '>
                  Put your job hunt on
                  <span class='cta-highlighted-color'> autopilot</span>
                </h1>

                <SignUpElementsForm
                  selectedPlan={selectedPlan}
                  email={user.email}
                  newsletterData={newsletterData}
           
                />
              </div>
              {/* 
              
               <Link to='profile/me'>
                {' '}
                <h4 className='margin-bottom-2 text-center'>OR </h4>
                <div className='margin-bottom-2 flex-center'>
                  <div className='row'>
                    <a className='button-secondary button-header-secondary w-button w--current margin-right-1'>
                      Create a free profile
                    </a>
                  </div>
                </div>
              </Link>
              
              */}
            </div>
          </div>
        </Layout>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  auth: state.authentication
})

export default connect(mapStateToProps, {})(PaymentForm)
