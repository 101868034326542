const prod = process.env.NODE_ENV === "production";

export const BASE_URL = prod
  ? process.env.REACT_APP_PROD_BASE_URL
  : process.env.REACT_APP_DEV_BASE_URL;

export const GOOGLE_URL = prod
  ? process.env.REACT_APP_PROD_GOOGLE_URL
  : process.env.REACT_APP_DEV_GOOGLE_URL;

export const LANDING_URL = prod
  ? process.env.REACT_APP_PROD_LANDING_URL
  : process.env.REACT_APP_DEV_LANDING_URL;
