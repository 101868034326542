import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const prod = process.env.NODE_ENV === "production";

let stripeKey;

stripeKey = prod
  ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_PROD
  : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;

const stripePromise = loadStripe(stripeKey);

const Layout = ({ children, title = "TypeScript Next.js Stripe Example" }) => (
  <Elements stripe={stripePromise}>
    <div>{children}</div>
  </Elements>
);

export default Layout;
