import React from 'react'
import PostJobOneOffPayment from './PostJobOneOffPaymentForm'
import Layout from './Layout'
import { connect } from 'react-redux'

const PaymentForm = ({ handleSuccessMessage, ...props }) => {
  const { priceChosen, postData } = props

  const jobTitle = postData.jobTitle
  const companyName = postData.companyName

  return (
    <>
      <Layout title='Payment with Checkout '>
        <div className='container margin-top-2'>
          <h3 className='text-align-center'>
            {jobTitle} at{' '}
            <span className='cta-highlighted-color'>{companyName}</span>
          </h3>
          <PostJobOneOffPayment
            priceChosen={priceChosen}
            postData={postData}
            handleSuccessMessage={handleSuccessMessage}
          />
        </div>
      </Layout>
    </>
  )
}

const mapStateToProps = state => ({
  auth: state.authentication
})

export default connect(mapStateToProps, {})(PaymentForm)
