const countries = [
  "United States",
  "United Kingdom",
  "France",
  "Spain",
  "Sweden",
  "Netherlands",
  "Australia",
  "Germany",
  "Belgium",
  "Singapore",
  "Hong Kong",
  "Canada",
  "Switzerland",
  "Italy",
  "Argentina",
  "Austria",
  "Brazil",
  "Denmark",
  "India",
  "Ireland",
  "Poland",
  "Portugal",
  "South Africa",
  "Remote",
];
export default countries;
