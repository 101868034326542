import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { getCurrentPrices, getMyNewsletter } from '../../../utils/api'
import isEmpty from '../../../validation/is-empty'
const Start = ({ ...props }) => {
  const history = useHistory()

  const [startingPriceJobs, setStartingPriceJobs] = useState(null)
  const [startingPriceBundle, setStartingPriceBundle] = useState(null)
  const [trialDays, setTrialDays] = useState(0)

  useEffect(() => {
    const { auth } = props
    const isAuthenticated = auth.isAuthenticated

    if (isAuthenticated) {
      getMyNewsletter().then(res => {
        const data = res.data

        console.log(data)
        if (
          isEmpty(data) ||
          (!isEmpty(data) && !data.isAccountActive) ||
          (!isEmpty(data) && data.newsletterInfo.formula === 'free')
        ) {
          getCurrentPrices().then(res => {
            const initialPriceJobs =
              res.prices.filter(
                x => x.proposition === 'jobs' && x.plan === 'monthly'
              )[0].details[0].unit_amount / 100
            const initialPriceBundle =
              res.prices.filter(
                x => x.proposition === 'bundle' && x.plan === 'monthly'
              )[0].details[0].unit_amount / 100
            setStartingPriceJobs(initialPriceJobs)
            setStartingPriceBundle(initialPriceBundle)

            setTrialDays(res.trialDays)
          })
        } else {
          history.push('/newsletter')
        }
      })
    } else {
      history.push('/login')
    }
  }, [])

  return (
    <>
      {trialDays && (
        <div className='section price-tables wf-section'>
          <div className='container-small-580 text-center'>
            <h1
              data-w-id='8c7e9cbe-687f-aa16-9a93-15e76fa70920'
              style={{
                transform:
                  'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                opacity: 1,
                transformStyle: 'preserve-3d'
              }}
            >
              Featured Jobs
              <span className='color-primary-1 underline' />
            </h1>
            <p
              data-w-id='8c7e9cbe-687f-aa16-9a93-15e76fa70924'
              style={{ opacity: 1 }}
              className='paragraph-large'
            >
              Please choose the featured job package that
              <br /> best fit your business needs.
            </p>
          </div>
          <div className='container-medium'>
            <div className='mg-top-48px'>
              <div className='w-dyn-list'>
                <div role='list' className='w-layout-grid price-tables-grid'>
                  <div
                    id='w-node-_1310cdec-a7e2-a1ed-fd15-0bfc82b5b509-c320b413'
                    data-w-id='1310cdec-a7e2-a1ed-fd15-0bfc82b5b509'
                    style={{ opacity: 1 }}
                    role='listitem'
                    className='price-table-card featured-jobs w-dyn-item'
                  >
                    <img
                      src='https://assets.website-files.com/5ec5d86528da2f27e60d6355/5eeae530412ffc0ae803fab9_Basic.svg'
                      alt=''
                    />
                    <h2>PRO Lite</h2>
                    <p>
                      For active candidates only interested in getting job
                      offers
                    </p>
                    <div
                      data-wf-sku-bindings='%5B%7B%22from%22%3A%22f_price_%22%2C%22to%22%3A%22innerHTML%22%7D%5D'
                      className='price featured-jobs'
                    >
                      ${startingPriceJobs}&nbsp;/month
                    </div>
                    <div className='job-card-info-bottom  margin-top-1'>
                      <div class='featured-badge'>Cancel anytime</div>
                      <a className='job-badge card margin-top-1'>
                        {trialDays > 0 && `Free for ${trialDays} days`}
                      </a>
                    </div>
                    <div className='divider price-table products' />
                    <ul role='list' className='product-features-container'>
                      <li className='feature-item'>
                        <img
                          src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc143f38d398d8c7bfbc7d_icon-submit-jobs-template.svg'
                          alt=''
                          className='feature-check-icon'
                        />
                        <div className='advantage'>
                          Create a free profile for recruiters
                        </div>
                      </li>
                      <li className='feature-item'>
                        <img
                          src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc143f38d398d8c7bfbc7d_icon-submit-jobs-template.svg'
                          alt=''
                          className='feature-check-icon'
                        />
                        <div className='advantage'>
                          Personalized newsletter with job offers based on your
                          skills, job title and location
                        </div>
                      </li>
                      <li className='feature-item'>
                        <img
                          src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc143f38d398d8c7bfbc7d_icon-submit-jobs-template.svg'
                          alt=''
                          className='feature-check-icon'
                        />
                        <div className='advantage'>Unlimited applications</div>
                      </li>
                    </ul>
                    <Link to='/payment/candidate/jobs'>
                      <a className='button-primary large full-width w-button'>
                        Continue
                      </a>
                    </Link>
                  </div>
                  <div
                    id='w-node-_1310cdec-a7e2-a1ed-fd15-0bfc82b5b509-c320b413'
                    data-w-id='1310cdec-a7e2-a1ed-fd15-0bfc82b5b509'
                    style={{ opacity: 1 }}
                    role='listitem'
                    className='price-table-card featured-jobs w-dyn-item'
                  >
                    <img
                      src='https://assets.website-files.com/5ec5d86528da2f27e60d6355/5eeae52851c39abf801f9bd2_Standard.svg'
                      alt=''
                    />
                    <h2>PRO Gold</h2>
                    <p>For very active candidates</p>
                    <div
                      data-wf-sku-bindings='%5B%7B%22from%22%3A%22f_price_%22%2C%22to%22%3A%22innerHTML%22%7D%5D'
                      className='price featured-jobs'
                    >
                      ${startingPriceBundle}&nbsp;/month
                    </div>
                    <div className='job-card-info-bottom margin-top-1'>
                      <div class='featured-badge'>Cancel anytime</div>
                      <a className='job-badge card margin-top-1'>
                        {trialDays > 0 && `Free for ${trialDays} days`}
                      </a>
                    </div>
                    <div className='divider price-table products' />
                    <ul role='list' className='product-features-container'>
                      <li className='feature-item'>
                        <img
                          src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc143f38d398d8c7bfbc7d_icon-submit-jobs-template.svg'
                          alt=''
                          className='feature-check-icon'
                        />
                        <div className='advantage'>
                          Create a free profile for recruiters
                        </div>
                      </li>
                      <li className='feature-item'>
                        <img
                          src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc143f38d398d8c7bfbc7d_icon-submit-jobs-template.svg'
                          alt=''
                          className='feature-check-icon'
                        />
                        <div className='advantage'>
                          Personalized newsletter with job offers based on your
                          skills, job title and location
                        </div>
                      </li>
                      <li className='feature-item'>
                        <img
                          src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc143f38d398d8c7bfbc7d_icon-submit-jobs-template.svg'
                          alt=''
                          className='feature-check-icon'
                        />
                        <div className='advantage'>
                          Interview questions on Monday and answers on Tuesday
                        </div>
                      </li>
                      <li className='feature-item'>
                        <img
                          src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc143f38d398d8c7bfbc7d_icon-submit-jobs-template.svg'
                          alt=''
                          className='feature-check-icon'
                        />
                        <div className='advantage'>Unlimited applications</div>
                      </li>
                    </ul>
                    <Link to='/payment/candidate/bundle'>
                      {' '}
                      <a className='button-primary large full-width w-button'>
                        Continue
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  auth: state.authentication
})

export default connect(mapStateToProps, {})(Start)
