import React, { Component } from "react";
import { LANDING_URL } from "../utils/url";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <footer className="footer">
        <div className="container-default footer-container">
          <div className="w-layout-grid footer-grid">
            <div
              data-w-id="3d726284-f6bb-3a12-f81e-5f8e3874b8e7"
              style={{ opacity: 1 }}
            >
              <h3 className="footer-title">
                Find your next great opportunity!
              </h3>
              <p>
                Join our newsletter and receive the best job openings every week
                on your inbox.
              </p>
              <div className="row justify-center">
                <Link to="signup">
                  {" "}
                  <a class="button-primary button-header-primary w-button">
                    Sign up
                  </a>
                </Link>
              </div>
            </div>
            <div>
              <div
                data-w-id="6664d1ce-10c1-caea-a340-642053fcd291"
                className="w-layout-grid footer-grid-menu"
                style={{ opacity: 1 }}
              >
                <div>
                  <h3 className="footer-title menu">Pages</h3>
                  <div className="_2-menus-footer">
                    <ul
                      role="list"
                      className="footer-menu-container right-menu"
                    >
                      <li className="footer-link-wrapper">
                        <a
                          className="footer-link"
                          href={LANDING_URL + "privacy"}
                        >
                          Privacy
                        </a>
                      </li>
                      {/* 
                      
                      <li className='footer-link-wrapper'>
                        <a className='footer-link' href={LANDING_URL + 'blog'}>
                          Blog
                        </a>
                      </li>
                      */}

                      <li className="footer-link-wrapper">
                        <a className="footer-link" href={LANDING_URL + "jobs"}>
                          Jobs
                        </a>
                      </li>
                    </ul>
                    <ul role="list" className="footer-menu-container">
                      <li className="footer-link-wrapper">
                        <a
                          className="footer-link"
                          href={LANDING_URL + "newsletter"}
                        >
                          Newsletter
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* 
                
                 <div>
                  <h3 className='footer-title menu'>Follow Us</h3>
                  <ul role='list' className='footer-menu-container right-menu'>
                    <li className='footer-link-wrapper'>
                      <a
                        href='http://twitter.com/securityjobslist'
                        className='social-media-link-footer w-inline-block'
                      >
                        <img
                          src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec98269518a6e6118d567d1_twitter.svg'
                          alt=''
                          className='social-icon-foote'
                        />
                        <div>Twitter</div>
                      </a>
                    </li>
                    <li className='footer-link-wrapper'>
                      <a
                        href='http://instagram.com/securityjobslist'
                        className='social-media-link-footer w-inline-block'
                      >
                        <img
                          src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ec982692fa1e9d0c1a8db49_instagram.svg'
                          alt=''
                          className='social-icon-foote'
                        />
                        <div>Instagram</div>
                      </a>
                    </li>
                  </ul>
                </div>*/}
              </div>
            </div>
          </div>
          <div
            data-w-id="43672ddb-3571-2ab6-380c-ff151fdf1f14"
            className="w-layout-grid copyright-grid"
            style={{ opacity: 1 }}
          >
            <a
              href="/"
              aria-current="page"
              className="w-inline-block w--current"
            >
              <img
                src="/images/vector/default.svg"
                alt=""
                className="footer-logo logo-website"
              />
            </a>
            <div className="text-block">
              © {/* */}
              {year}
              {/* */} Copyright &nbsp;Security Jobs List{/* */}{" "}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
