import React, { useState, useEffect, useRef } from "react";
import countries from "./../../utils/listCountriesProfile";
import experienceYears from "./../../utils/listExperienceYears";
import jobs from "./../../utils/listJobs";
import skills from "./../../utils/listSkills";
import ConfirmUploadDialog from "./confirmUploadPhotoDialogJobAd";
import { useHistory } from "react-router-dom";
import { getCurrentPrices } from "../../utils/api";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Editor } from "@tinymce/tinymce-react";
import "react-circular-progressbar/dist/styles.css";
import { useForm } from "react-hook-form";
import Payment from "../Payment/paymentFormJobOffer";

const PostJob = ({ ...props }) => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const { auth } = props;
  const [isLoading, setIsLoading] = useState(false);

  const isAuthenticated = auth.isAuthenticated;

  const [companyLogo, setCompanyLogo] = useState([]);
  const [jobFamily, setJobFamily] = useState("Cyber Security");
  const [countrySelected, setCountrySelected] = useState("United States");
  const [jobDescriptionHTML, setJobDescriptionHTML] = useState(
    "<p>Insert your job description here</>"
  );
  const [skillsSelected, setSkillsSelected] = useState([]);
  const [experienceYearsSelected, setExperienceYearsSelected] = useState([]);

  const [firstSectionVisible, setFirstSectionVisible] = useState(false);
  const [secondSectionVisible, setSecondSectionVisible] = useState(false);
  const [thirdSectionVisible, setThirdSectionVisible] = useState(false);

  const [showDialogUpload, setShowDialogUpload] = useState(false);
  const [photo, setPhoto] = useState("");
  const [file, setFile] = useState(null);
  const [errorSize, setErrorSize] = useState(null);
  const [isImageLoaded, setImageLoaded] = useState(true);

  const [priceObject, setPriceObject] = useState({});
  const [initialPrice, setInitialPrice] = useState(null);
  const [infoPostCompleted, setInfoPostCompleted] = useState(false);
  const [infoForPayment, setInfoForPayment] = useState(null);

  const [successMessage, setSuccessMessage] = useState(null);

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
  };

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const setDialogUploadToFalse = () => {
    setShowDialogUpload(false);
    setFile(null);
  };

  const setSuccessUpload = (imageData) => {
    setShowDialogUpload(false);
    setFile(null);
    setCompanyLogo(imageData);
    setIsLoading(false);
    //setIsUpdated(true);
    setImageLoaded(false);
  };

  const handleErrorSize = (message) => {
    setErrorSize(message);
  };

  const handleLoadingUpload = (value) => {
    setIsLoading(value);
  };

  const removeErrorSize = () => {
    setErrorSize(null);
  };

  const onChange = (e) => {
    setFile(e.target.files[0]);
    setShowDialogUpload(true);
  };

  const selectCountry = (job) => {
    if (job === "Select a country") {
      setCountrySelected(null);
    } else {
      setCountrySelected(job);
    }
  };
  const selectJobs = (job) => {
    if (job === "Select a job") {
      setJobFamily(null);
    } else {
      setJobFamily(job);
    }
  };

  const handleEditorChange = (content, editor) => {
    setJobDescriptionHTML(content);
  };

  useEffect(() => {
    setIsLoading(true);

    const { auth } = props;
    setIsLoading(true);

    const isAuthenticated = auth.isAuthenticated;
    const userType = auth.user.userType;

    if (isAuthenticated && userType === "candidate") {
      history.push("/");
    }

    if (!isAuthenticated) {
      getCurrentPrices().then((res) => {
        const prices = res.prices;

        const postJobPrices = prices.filter((x) => x.plan === "one-off");
        const firstPriceObject = postJobPrices[0];
        setPriceObject(firstPriceObject);
        setInitialPrice(firstPriceObject.details[0].unit_amount / 100);
      });
    }

    setIsLoading(false);
  }, []);

  const onSubmit = (data) => {
    data["countryName"] = countrySelected;
    data["skills"] = skillsSelected;
    data["experienceYears"] = experienceYearsSelected;
    data["isCreatingPostOffer"] = true;
    data["companyLogo"] = companyLogo;
    data["jobDescription"] = jobDescriptionHTML;
    setInfoForPayment(data);
    setInfoPostCompleted(true);
  };

  return (
    <>
      <div className="section short wf-section">
        <div className="container-default">
          <div className="w-layout-grid grid-form-container">
            <div className="form-text-container">
              <div
                data-w-id="6f67ce88-e309-b69f-9111-b03f066650c4"
                style={{ opacity: 1 }}
                className="card free-job"
              >
                <div className="text-center">
                  <h2
                    data-w-id="5ddd04ef-7b85-1806-84ab-d667652bbbaa"
                    style={{
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      opacity: 1,
                      transformStyle: "preserve-3d",
                    }}
                  >
                    The #1 Security Jobs board.
                  </h2>
                  <p
                    data-w-id="60d582bc-420f-bc00-7a29-0a402e10e3e4"
                    style={{ opacity: 1 }}
                    className=" about-us"
                  >
                    The fastest-growing online job board for security experts
                  </p>
                </div>
                <li className="feature-item">
                  <img
                    src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc143f38d398d8c7bfbc7d_icon-submit-jobs-template.svg"
                    alt=""
                    className="feature-check-icon"
                  />
                  <div className="advantage">
                    Featured on top of the frontpage for 30 days
                  </div>
                </li>
                <li className="feature-item">
                  <img
                    src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc143f38d398d8c7bfbc7d_icon-submit-jobs-template.svg"
                    alt=""
                    className="feature-check-icon"
                  />
                  <div className="advantage">
                    Emailed to +5<span class="color-primary-1">K</span> job
                    seekers
                  </div>
                </li>
                <li className="feature-item">
                  <img
                    src="https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecc143f38d398d8c7bfbc7d_icon-submit-jobs-template.svg"
                    alt=""
                    className="feature-check-icon"
                  />
                  <div className="advantage">
                    Posted once a week on our social media channels
                  </div>
                </li>

                <a
                  href="#job-information"
                  className={`form-sidebar-link ${
                    firstSectionVisible ? "w--current" : null
                  }`}
                >
                  1. Job information
                </a>
                <a
                  href="#company-information"
                  className={`form-sidebar-link ${
                    secondSectionVisible ? "w--current" : null
                  }`}
                >
                  2. Company information
                </a>
                <a
                  href="#recruiter-information"
                  className={`form-sidebar-link last ${
                    thirdSectionVisible ? "w--current" : null
                  }`}
                >
                  3. Recruiter information
                </a>
              </div>
            </div>
            <div
              id="w-node-_26fe0a1e-561a-2107-9041-9306138886fb-270281fa"
              data-w-id="26fe0a1e-561a-2107-9041-9306138886fb"
              style={{ opacity: 1 }}
              className="form-container"
            >
              <div className="w-form">
                <form
                  id="email-form-2"
                  name="email-form-2"
                  data-name="Email Form 2"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div
                    id="job-information"
                    onMouseEnter={() =>
                      setFirstSectionVisible(!firstSectionVisible)
                    }
                    onMouseLeave={() =>
                      setFirstSectionVisible(!firstSectionVisible)
                    }
                    className="w-layout-grid grid-form"
                  >
                    <h2
                      id="w-node-a3d1ad77-e5aa-114b-bcd7-cde3db1bb746-270281fa"
                      className="form-subtitle"
                    >
                      1. Job information
                    </h2>
                    <div
                      id="w-node-_11ff66e2-bb63-3205-39c9-a48a569518d9-270281fa"
                      className="input-container-full-width"
                    >
                      <label htmlFor="Job-Title-2">
                        Job title<span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="input w-input"
                        placeholder="Senior Data Scientist"
                        {...register("jobTitle", {
                          required: "Please enter your current job title",
                          minLength: {
                            value: 2,
                            message: "Min length is 2",
                          },
                          maxLength: {
                            value: 80,
                            message: "Max length is 80",
                          },
                        })}
                      />
                    </div>
                    {errors.jobTitle && (
                      <h3 className="error__invalid">
                        {errors.jobTitle.message}
                      </h3>
                    )}

                    <div>
                      <label htmlFor="job-title-3">
                        Job family<span className="color-red">*</span>
                      </label>
                      <select
                        className="input w-input"
                        name="jobFamily"
                        value={jobFamily}
                        onChange={(e) => selectJobs(e.target.value)}
                      >
                        {jobs.map((job, i) => (
                          <option key={i} value={job}>
                            {job}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label htmlFor="job-title-3">
                        Country<span className="color-red">*</span>
                      </label>
                      <select
                        className="input w-input"
                        name="countrySelected"
                        value={countrySelected}
                        onChange={(e) => selectCountry(e.target.value)}
                      >
                        {countries.map((country, i) => (
                          <option key={i} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label htmlFor="Job-Location">
                        Job location<span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="input w-input"
                        placeholder="San Francisco"
                        {...register("location", {
                          required: "Please enter your current job title",
                          minLength: {
                            value: 2,
                            message: "Min length is 2",
                          },
                          maxLength: {
                            value: 80,
                            message: "Max length is 80",
                          },
                        })}
                      />
                    </div>
                    {errors.location && (
                      <h3 className="error__invalid">
                        {errors.location.message}
                      </h3>
                    )}
                    <div>
                      <label htmlFor="Job-Location">
                        Job Offer Link<span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="input w-input"
                        placeholder="Application Link (example: https://www.company.com/jobs/offer/data-scientist)"
                        {...register("jobOfferLink", {
                          required: "Please enter your Application Link",
                          pattern: {
                            value: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                            message: "Please enter a valid url",
                          },
                        })}
                      />
                    </div>
                    {errors.jobOfferLink && (
                      <h3 className="error__invalid">
                        {errors.jobOfferLink.message}
                      </h3>
                    )}
                    <div
                      id="w-node-_19e3179a-ebf7-e568-5dcf-3c0e607846d8-270281fa"
                      className="input-container-full-width"
                    >
                      <label htmlFor="Job-Description">
                        Experience<span className="color-red">*</span>
                      </label>
                      <Autocomplete
                        multiple
                        id="size-small-standard-multi"
                        size="small"
                        options={experienceYears}
                        getOptionLabel={(option) => option}
                        onChange={(event, newValue) => {
                          setExperienceYearsSelected(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Experience"
                            placeholder="Experience required"
                          />
                        )}
                      />
                    </div>
                    <div
                      id="w-node-_19e3179a-ebf7-e568-5dcf-3c0e607846d8-270281fa"
                      className="input-container-full-width"
                    >
                      <label htmlFor="Job-Description">
                        Skills<span className="color-red">*</span>
                      </label>
                      <Autocomplete
                        multiple
                        id="size-small-standard-multi"
                        size="small"
                        options={skills}
                        getOptionLabel={(option) => option}
                        onChange={(event, newValue) => {
                          setSkillsSelected(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Skills"
                            placeholder="Select skills"
                          />
                        )}
                      />
                    </div>
                    <div
                      id="w-node-_19e3179a-ebf7-e568-5dcf-3c0e607846d8-270281fa"
                      className="input-container-full-width"
                    >
                      <label htmlFor="Job-Description">
                        Job description<span className="color-red">*</span>
                      </label>
                      <div className="">
                        <Editor
                          apiKey="ca8orwcw7l4hpnd97jtwiopjqcd63t2uke2s8yxnqa9eabku"
                          initialValue={"<p>Insert text here</>"}
                          value={jobDescriptionHTML}
                          init={{
                            height: 500,
                            plugins: "autoresize",
                            menubar: true,
                            mobile: { toolbar_drawer: "floating" },
                            plugins:
                              "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                            toolbar:
                              "undo redo | formatselect | bold italic backcolor | \
 alignleft aligncenter alignright alignjustify | \
 bullist numlist outdent indent | removeformat | help",
                          }}
                          onEditorChange={handleEditorChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="divider form" />
                  <div
                    id="company-information"
                    className="w-layout-grid grid-form"
                    onMouseEnter={() =>
                      setSecondSectionVisible(!secondSectionVisible)
                    }
                    onMouseLeave={() =>
                      setSecondSectionVisible(!secondSectionVisible)
                    }
                  >
                    <h2
                      id="w-node-_4ef0b413-e918-4e38-caf9-14a7d812a0cf-270281fa"
                      className="form-subtitle"
                    >
                      2. Company information
                    </h2>
                    <div
                      id="w-node-_4ef0b413-e918-4e38-caf9-14a7d812a0fb-270281fa"
                      className="input-container-full-width"
                    >
                      <label htmlFor="Company-Name">
                        Company name<span className="color-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="input w-input"
                        placeholder="Google"
                        {...register("companyName", {
                          required: "Please enter a company name",
                          minLength: {
                            value: 2,
                            message: "Min length is 2",
                          },
                          maxLength: {
                            value: 80,
                            message: "Max length is 80",
                          },
                        })}
                      />
                    </div>
                    {errors.companyName && (
                      <h3 className="error__invalid">
                        {errors.companyName.message}
                      </h3>
                    )}
                    <div>
                      <label>Upload company logo</label>

                      {!isImageLoaded && (
                        <div className="flex-center">
                          <div className="reverse-spinner"></div>
                        </div>
                      )}
                      <div
                        className={
                          isImageLoaded
                            ? "upload-photo-container"
                            : "upload-photo-hidden-input"
                        }
                      >
                        <img
                          id="image"
                          className="company-logo-recruiter"
                          onLoad={handleImageLoaded}
                          src={
                            companyLogo.url
                              ? companyLogo.url
                              : "https://yoshi-projects.s3.eu-west-3.amazonaws.com/Datayoshi/Assets/no-logo-company.jpg"
                          }
                          alt="company pic"
                        />

                        <label
                          htmlFor="fileInput"
                          type="button"
                          className="button-primary button-header-primary w-button margin-top-1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="upload-photo-icon"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <rect
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                              stroke="none"
                            ></rect>
                            <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2"></path>
                            <circle cx="12" cy="13" r="3"></circle>
                          </svg>
                          Upload Logo
                        </label>
                        {errorSize && (
                          <h4 className="error__wrong text-center my-4">
                            😥 {errorSize}
                          </h4>
                        )}

                        <input
                          name="photo"
                          id="fileInput"
                          accept="image/*"
                          className="upload-photo-hidden-input"
                          type="file"
                          onChange={onChange}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="divider form" />
                  <div
                    id="recruiter-information"
                    className="w-layout-grid grid-form submit"
                    onMouseEnter={() =>
                      setThirdSectionVisible(!thirdSectionVisible)
                    }
                    onMouseLeave={() =>
                      setThirdSectionVisible(!thirdSectionVisible)
                    }
                  >
                    <h2
                      id="w-node-_77244f4d-0b97-b78d-f847-65633e972a83-270281fa"
                      className="form-subtitle"
                    >
                      3. Recruiter information
                    </h2>
                    <div
                      id="w-node-_4ef0b413-e918-4e38-caf9-14a7d812a0fb-270281fa"
                      className="input-container-full-width"
                    >
                      <label htmlFor="Email-4">
                        Email<span className="color-red">*</span>
                      </label>
                      <input
                        type="email"
                        className="input w-input"
                        placeholder="john@google.com"
                        {...register("emailAddressRecruiter", {
                          required: "Please enter your e-mail address",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Enter a valid e-mail address",
                          },
                        })}
                      />
                    </div>
                    {errors.emailAddressRecruiter && (
                      <h3 className="error__invalid">
                        {errors.emailAddressRecruiter.message}
                      </h3>
                    )}

                    <div
                      id="w-node-_77244f4d-0b97-b78d-f847-65633e972a8d-270281fa"
                      className="divider form last"
                    />
                  </div>
                  {!infoPostCompleted && !infoForPayment && (
                    <div className="flex-center margin-top-1">
                      <button
                        type="submit"
                        className="button-primary large full-width w-button"
                      >
                        Post your job - ${initialPrice} (
                        <del>
                          {(initialPrice / 100) % 5 !== 0
                            ? ` $${initialPrice * 2 + 1}`
                            : ` $${initialPrice * 2}`}
                        </del>
                        )
                      </button>
                    </div>
                  )}
                </form>

                {infoPostCompleted && infoForPayment && (
                  <Payment
                    priceChosen={priceObject}
                    postData={infoForPayment}
                    handleSuccessMessage={handleSuccessMessage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDialogUpload && !isLoading && (
        <ConfirmUploadDialog
          setDialogUploadToFalse={setDialogUploadToFalse}
          handleErrorSize={handleErrorSize}
          removeErrorSize={removeErrorSize}
          handleLoadingUpload={handleLoadingUpload}
          photo={photo}
          fileUploaded={file}
          setSuccessUpload={setSuccessUpload}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authentication,
});

export default connect(mapStateToProps, {})(PostJob);
