const frequencies = [
  {
    frequencyLabel: 'Once a week (Mon)',
    frequencyValue: 'freq-1'
  },
  {
    frequencyLabel: 'Twice a week (Mon, Wed)',
    frequencyValue: 'freq-2'
  },
  {
    frequencyLabel: 'Three times a week (Mon, Wed, Fri)',
    frequencyValue: 'freq-3'
  }
]
export default frequencies
