import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { unSubscribeNewsletter, activateNewsletter } from '../../utils/api'

import { connect } from 'react-redux'

const ConfirmUnsusbscribeActivate = ({
  setDialogUploadToFalse,
  handleCancellationMessage,
  isSubscribedNewsletter,
  newsletterId
}) => {
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
    setDialogUploadToFalse()
  }

  const unSubscribeNewsletterUser = () => {
    unSubscribeNewsletter({ newsletterId }).then(res => {
      handleCancellationMessage(res.message)
      handleClose()
    })
  }

  const activateNewsletterUser = () => {
    activateNewsletter({ newsletterId }).then(res => {
      handleCancellationMessage(res.message)
      handleClose()
    })
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {isSubscribedNewsletter ? 'Pause ' : 'Activate '}
          Newsletter
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            You will now {isSubscribedNewsletter ? 'pause ' : 'activate '} your{' '}
            newsletter. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancel
          </Button>
          <Button
            onClick={
              isSubscribedNewsletter
                ? unSubscribeNewsletterUser
                : activateNewsletterUser
            }
            color='primary'
            autoFocus
          >
            {isSubscribedNewsletter ? 'Pause ' : 'Activate '} my newsletter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = state => ({
  auth: state.authentication,
  profile: state.userProfile
})

export default connect(mapStateToProps, {})(ConfirmUnsusbscribeActivate)
