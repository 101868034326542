import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

function Home ({ ...props }) {
  const history = useHistory()
  useEffect(() => {
    const redirectPage = () => {
      const { auth } = props

      const isAuthenticated = auth.isAuthenticated
      const user = auth.user

      if (!isAuthenticated) {
        history.push('/signup')
      } else {
        history.push('/start')
      }
    }
    redirectPage()
  }, [])

  return <></>
}

const mapStateToProps = state => ({
  auth: state.authentication
})

export default connect(mapStateToProps, {})(Home)
