import React, { useState, useEffect } from 'react'
import Navbar from './components/Navbar'
import Footer from './components/Footer'

import Home from './components/Home'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import DynamicScrollToTop from './utils/DynamicScrollToTop'

import RedirectOffer from './components/Jobs/redirectToOffer'
import PostJob from './components/Jobs/postJob'

import Start from './components/Start/start.js'
import Plans from './components/Start/Pricing/plans'
import Login from './components/Sign/Login.js'
import Signup from './components/Sign/Signup.js'
import ForgotPassword from './components/Sign/ForgotPassword.js'
import ResetPassword from './components/Sign/ResetPassword.js'
import PaymentCandidate from './components/Payment/paymentCandidate.js'

import Authenticating from './components/Authenticating'
import ShareSocial from './components/Share/shareSocial'

import Newsletter from './components/User/myNewsletter.js'
import MyProfile from './components/User/myProfile.js'

import { Provider } from 'react-redux'
import store from './store'
import { setCurrentUser, logoutUser } from './redux/actions/authActions'
import setAuthToken from './utils/setAuthToken'
import jwt_decode from 'jwt-decode'

import Cookies from 'universal-cookie'

import './styles/stylesheets/template-webflow/app.css'
import './App.css'

const cookies = new Cookies()

const token = cookies.get('sj_tkn_au')

let decoded

// Check for token
if (token) {
  const accessToken = cookies.get('sj_tkn_au')

  // Set auth token header auth
  setAuthToken(accessToken)
  // Decode token and get user info and exp
  decoded = jwt_decode(accessToken)

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded))

  // Check for expired token
  const currentTime = Date.now() / 1000
  if (decoded.exp < currentTime) {
    console.log(currentTime)
    // Logout user
    store.dispatch(logoutUser())
    // Redict to login
    window.location.href = '/login'
  }
}

function App () {
  const [hideFooter, setHideFooter] = useState(false)

  const handleFooter = () => {
    setHideFooter(!hideFooter)
  }

  useEffect(() => {
    document.body.classList.add('bg-neutral-100')
  }, [])
  return (
    <Provider store={store}>
      <Router>
        <div className='page-wrapper'>
          <Navbar />
          <DynamicScrollToTop />
          <Route exact path='/' component={Home} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/signup' component={Signup} />
          <Route exact path='/start' component={Start} />
          <Route exact path='/plans' component={Plans} />

          <Route
            exact
            path='/newsletter'
            render={props => (
              <Newsletter handleFooter={handleFooter} {...props} />
            )}
          />
          <Route exact path='/profile/me' component={MyProfile} />
          <Route exact path='/forgot-password' component={ForgotPassword} />
          <Route exact path='/reset-password/:id' component={ResetPassword} />
          <Route exact path='/hire' component={PostJob} />

          <Route
            exact
            path='/payment/candidate/:plan'
            component={PaymentCandidate}
          />

          <Route
            exact
            path='/offer/:slugNumber/:slugUrl'
            component={RedirectOffer}
          />

          <Route exact path='/authentication' component={Authenticating} />
          {/* 
          
            <Route
            exact
            path='/share/social/:jobOfferId'
            render={props => (
              <ShareSocial handleFooter={handleFooter} {...props} />
            )}
          />
          */}
        </div>
        {!hideFooter && <Footer />}
      </Router>
    </Provider>
  )
}

export default App
