import axios from 'axios'

const prod = process.env.NODE_ENV === 'production'
const BASE_URL = prod
  ? process.env.REACT_APP_PROD_BASE_URL
  : process.env.REACT_APP_DEV_BASE_URL

export const verifyPayment = data => {
  const url = BASE_URL + 'stripe/payment-intent'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const createUserOneOff = data => {
  const url = BASE_URL + 'auth/signup/one-off'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const createFreeProfile = data => {
  const url = BASE_URL + 'auth/signup/get-hired'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const upgradeUserOneOff = data => {
  const url = BASE_URL + 'auth/signup/upgrade'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const signUp = data => {
  const url = BASE_URL + 'auth/signup'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const verifyEmail = data => {
  const url = BASE_URL + 'auth/signup/verify'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const createCustomer = data => {
  const url = BASE_URL + 'stripe/create-customer'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const createSubscription = data => {
  const url = BASE_URL + 'stripe/create-subscription'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const createSubscriptionRecruiter = data => {
  const url = BASE_URL + 'stripe/create-subscription-recruiter'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const createSetupIntents = data => {
  const url = BASE_URL + 'stripe/setup-intents'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const confirmCreateAccountLink = data => {
  const url = BASE_URL + 'auth/create-account-link'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const createAccountUser = data => {
  const url = BASE_URL + 'auth/create-account'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const createAccountRecruiter = data => {
  const url = BASE_URL + 'auth/signup/recruiter'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const getMyNewsletter = () => {
  const url = BASE_URL + 'newsletter/my-newsletter'

  return axios
    .get(url)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const getUserPaidStatus = () => {
  const url = BASE_URL + 'newsletter/my-newsletter/type'

  return axios
    .get(url)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const createFreeNewsletter = () => {
  const url = BASE_URL + 'newsletter/create/free'

  return axios
    .post(url)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const createLifeTimeNewsletter = data => {
  const url = BASE_URL + 'newsletter/create/lifetime'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const getLatestSubscribersNumber = () => {
  const url = BASE_URL + 'newsletter/stats'

  return axios
    .get(url)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const updateMyNewsletter = data => {
  const url = BASE_URL + 'newsletter/my-newsletter/update'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const cancelSubscriptionCandidate = () => {
  const url = BASE_URL + 'stripe/cancel-subscription/candidate'

  return axios
    .post(url)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const cancelSubscriptionRecruiter = () => {
  const url = BASE_URL + 'stripe/cancel-subscription/recruiter'

  return axios
    .post(url)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const unSubscribeNewsletter = data => {
  const url = BASE_URL + 'newsletter/my-newsletter/unsubscribe'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const activateNewsletter = data => {
  const url = BASE_URL + 'newsletter/my-newsletter/activate'

  return axios
    .post(url, data)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const redirectToOffer = data => {
  const slugNumber = data.slugNumber
  const slugUrl = data.slugUrl

  const url = BASE_URL + 'jobs/offer/' + slugNumber + '/' + slugUrl

  return axios
    .get(url)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const redirectToOfferVisitor = data => {
  const slugNumber = data.slugNumber
  const slugUrl = data.slugUrl

  const url = BASE_URL + 'jobs/offer/' + slugNumber + '/' + slugUrl + '/visitor'

  return axios
    .get(url)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const createOrUpdatePostOffer = data => {
  const url = BASE_URL + 'jobs/offer/post'

  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const createPostOfferOneOffFree = data => {
  const url = BASE_URL + 'jobs/offer/post/one-off/free'

  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const createPostOfferOneOffPaid = data => {
  const url = BASE_URL + 'jobs/offer/post/one-off/paid'

  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const verifyCodePromoCode = data => {
  const url = BASE_URL + 'promocodes/verify'

  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const getOwnOffers = data => {
  const url = BASE_URL + 'jobs/my-offers'

  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const updateProfileUser = data => {
  const url = BASE_URL + 'profile/update'
  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const updateRecruiterInfo = data => {
  const url = BASE_URL + 'recruiter/update'
  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const getOwnProfile = () => {
  const url = BASE_URL + 'profile/me'
  return axios
    .get(url)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const getOwnProfileRecruiter = () => {
  const url = BASE_URL + 'recruiter/me'
  return axios
    .get(url)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const deletePhoto = data => {
  const url = BASE_URL + 'profile/photo/delete'

  //dispatch(setProfileLoading());
  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const getProfiles = data => {
  const url = BASE_URL + 'profile/profiles'

  //dispatch(setProfileLoading());
  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const getProfilesWithFiltersPublic = data => {
  const url = BASE_URL + 'profile/profiles/public/filtered'

  //dispatch(setProfileLoading());
  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const getProfilesWithFiltersPrivate = data => {
  const url = BASE_URL + 'profile/profiles/private/filtered'

  //dispatch(setProfileLoading());
  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const getProfilePublic = userId => {
  const url = BASE_URL + 'profile/public/member/' + userId

  //dispatch(setProfileLoading());
  return axios
    .get(url)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const getProfilePrivate = userId => {
  const url = BASE_URL + 'profile/private/member/' + userId

  //dispatch(setProfileLoading());
  return axios
    .get(url)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const unlockProfile = data => {
  const url = BASE_URL + 'profile/unlock-profile'

  //dispatch(setProfileLoading());
  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const unlockProfileEmail = data => {
  const url = BASE_URL + 'profile/unlock-profile-email'

  //dispatch(setProfileLoading());
  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const getInternshipsPrivate = () => {
  const url = BASE_URL + 'jobs/internships/private'

  return axios
    .get(url)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const getInternshipsPublic = () => {
  const url = BASE_URL + 'jobs/internships/public'

  return axios
    .get(url)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const getCurrentPrices = () => {
  const url = BASE_URL + 'prices/current'

  return axios
    .get(url)
    .then(res => res.data)
    .catch(err => err.response.data)
}

export const startCheckOut = data => {
  const url = BASE_URL + 'checkout/start'

  return axios
    .post(url, data)
    .then(res => {
      return res.data
    })
    .catch(err => {
      return err.response.data
    })
}

export const getOneOfferShareSocial = jobOfferId => {
  const url = BASE_URL + 'jobs/share/' + jobOfferId

  return axios
    .get(url)
    .then(res => res.data)
    .catch(err => err.response.data)
}
