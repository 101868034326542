const languagesSpoken = [
  "Afrikaans",
  "Albanian",
  "Arabic",
  "Armenian",
  "Belarusian",
  "Bengali",
  "Bosnian",
  "Bulgarian",
  "Burmese",
  "Catalan",
  "Chichewa",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Estonian",
  "Finnish",
  "French",
  "Galician",
  "Georgian",
  "German",
  "Greek",
  "Guaraní",
  "Gujarati",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Irish",
  "Igbo",
  "Icelandic",
  "Italian",
  "Japanese",
  "Javanese",
  "Khmer",
  "Kongo",
  "Korean",
  "Kurdish",
  "Latin",
  "Luxembourgish",
  "Luganda",
  "Limburgish",
  "Lingala",
  "Lao",
  "Lithuanian",
  "Latvian",
  "Malay",
  "Maltese",
  "Māori",
  "Norwegian",
  "Panjabi",
  "Persian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovene",
  "Somali",
  "Spanish",
  "Swahili",
  "Swati",
  "Swedish",
  "Tamil",
  "Tajik",
  "Thai",
  "Turkish",
  "Tatar",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Yoruba",
];

export default languagesSpoken;
