import React, { useState, useEffect, useRef } from 'react'
import countries from './../../utils/listCountriesProfile'
import languagesSpoken from './../../utils/listLanguagesSpoken'
import skills from './../../utils/listSkills'
import Checkbox from '@material-ui/core/Checkbox'
import { useHistory } from 'react-router-dom'
import { getOwnProfile, updateProfileUser } from '../../utils/api'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import ConfirmUploadDialog from './confirmUploadPhotoDialog'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  }
}))

const ManageNewsletter = ({ ...props }) => {
  const classes = useStyles()
  const history = useHistory()
  const didMountRef = useRef(false)
  const { auth } = props

  const userInfo = auth.user

  const [skillsAdded, setSkillsAdded] = useState([])
  const [languagesAdded, setLanguagesAdded] = useState([])
  const [availabilitySelected, setAvailabilitySelected] = useState([])
  const [experienceSelected, setExperienceSelected] = useState([])
  const [experience, setExperience] = useState(null)
  const [availabilityDate, setAvailabilityDate] = useState(null)
  const [availabilities, setAvailabilities] = useState(null)
  const [countrySelected, setCountrySelected] = useState('United States')
  const [bio, setBio] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [linkedin, setLinkedin] = useState('')
  const [github, setGithub] = useState('')
  const [website, setWebsite] = useState('')
  const [currentSalary, setCurrentSalary] = useState('')
  const [expectedSalary, setExpectedSalary] = useState('')
  const [photo, setPhoto] = useState('')
  const [percentage, setPercentage] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [showDialogUpload, setShowDialogUpload] = useState(false)
  const [file, setFile] = useState(null)
  const [errorSize, setErrorSize] = useState(null)
  const [isImageLoaded, setImageLoaded] = useState(true)

  const handleImageLoaded = () => {
    setImageLoaded(true)
  }

  const setDialogUploadToFalse = () => {
    setShowDialogUpload(false)
    setFile(null)
  }

  const setSuccessUpload = () => {
    setShowDialogUpload(false)
    setFile(null)
    setIsUpdated(true)
    setImageLoaded(false)
  }

  const handleErrorSize = message => {
    setErrorSize(message)
  }

  const handleLoadingUpload = value => {
    setIsLoading(value)
  }

  const removeErrorSize = () => {
    setErrorSize(null)
  }

  const onChange = e => {
    setFile(e.target.files[0])
    setShowDialogUpload(true)
  }

  const convertDateToYYYYMMDD = date => {
    const newDate = new Intl.DateTimeFormat('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(date)
    return newDate
  }

  const availabilityAnswers = [
    'Available now',
    'Available later',
    'Not available'
  ]

  const experienceAnswers = [
    '0-3 years',
    '3-5 years',
    '5-10 years',
    '10+ years'
  ]

  const style = {
    marginLeft: '-0.5rem'
  }

  const selectCountry = country => {
    if (country === 'Select a country') {
      setCountrySelected(null)
    } else {
      setCountrySelected(country)
    }
  }

  const handleChangeSkills = event => {
    if (event.target.checked) {
      setSkillsAdded([...skillsAdded, event.target.name])
    } else {
      let filteredArray = skillsAdded.filter(item => item !== event.target.name)
      setSkillsAdded(filteredArray)
    }
  }

  const handleChangeLanguages = event => {
    if (event.target.checked) {
      setLanguagesAdded([...languagesAdded, event.target.name])
    } else {
      let filteredArray = languagesAdded.filter(
        item => item !== event.target.name
      )
      setLanguagesAdded(filteredArray)
    }
  }

  const handleExperience = event => {
    setExperienceSelected([event.target.name])
    setExperience(event.target.name)
  }

  const handleAvailabilities = event => {
    if (event.target.checked) {
      setAvailabilitySelected([event.target.name])

      if (event.target.name !== 'Available later') {
        setAvailabilities({
          isAvailable: event.target.name,
          availabilityDate: null
        })
      } else {
        if (!availabilityDate) {
          setAvailabilityDate(new Date(Date.now()))
          setAvailabilities({
            isAvailable: event.target.name,
            availabilityDate: new Date(Date.now())
          })
        } else {
          setAvailabilityDate(availabilityDate)
          setAvailabilities({
            isAvailable: event.target.name,
            availabilityDate: availabilityDate
          })
        }
      }
    }
  }

  const onSubmit = e => {
    e.preventDefault()
    let userData = {}
    setIsLoading(true)

    if (countrySelected) userData.country = countrySelected
    if (skillsAdded) userData.skills = skillsAdded
    if (currentSalary) userData.currentSalary = currentSalary
    if (expectedSalary) userData.expectedSalary = expectedSalary
    if (languagesAdded) userData.languagesSpoken = languagesAdded
    if (availabilities) userData.availabilities = availabilities
    if (experience) userData.experience = experience
    if (firstName) userData.firstName = firstName
    if (lastName) userData.lastName = lastName
    if (bio) userData.bio = bio
    if (linkedin) userData.linkedin = linkedin
    if (github) userData.github = github
    if (website) userData.website = website

    updateProfileUser(userData)
      .then(res => {
        console.log(res)
        if (res.success && !res.error) {
          setIsUpdated(true)
        }
      })
      .catch(err => {
        //setError(err.response.data.message);
      })
  }

  const fetchUserData = () => {
    const { auth } = props
    setIsLoading(true)

    const isAuthenticated = auth.isAuthenticated

    if (isAuthenticated) {
      getOwnProfile().then(res => {
        const data = res.data

        let dataCollected = {}
        if (data) {
          if (data.firstName) {
            setFirstName(data.firstName)
            dataCollected['firstName'] = data.firstName
          }

          if (data.lastName) {
            setLastName(data.lastName)
            dataCollected['lastName'] = data.lastName
          }

          if (data.currentSalary) {
            setCurrentSalary(data.currentSalary)
            dataCollected['currentSalary'] = data.currentSalary
          }
          if (data.country) {
            setCountrySelected(data.country)
            dataCollected['country'] = data.country
          }

          if (data.currentSalary) {
            setCurrentSalary(data.currentSalary)
            dataCollected['currentSalary'] = data.currentSalary
          }
          if (data.expectedSalary) {
            setExpectedSalary(data.expectedSalary)
            dataCollected['expectedSalary'] = data.expectedSalary
          }

          if (data.skills && data.skills.length > 0) {
            setSkillsAdded(data.skills)
            dataCollected['skills'] = data.skills
          }
          if (data.languagesSpoken && data.languagesSpoken.length > 0) {
            setLanguagesAdded(data.languagesSpoken)
            dataCollected['languagesSpoken'] = data.languagesSpoken
          }
          if (data.experience) {
            setExperience(data.experience)
            dataCollected['experience'] = data.experience
          }

          if (data.experience) setExperienceSelected([data.experience])
          if (data.availabilities && data.availabilities.isAvailable) {
            setAvailabilitySelected([data.availabilities.isAvailable])
            dataCollected['availabilities'] = data.availabilities
          }

          if (
            data.availabilities &&
            data.availabilities.isAvailable === 'Available later'
          ) {
            setAvailabilities({
              isAvailable: data.availabilities.isAvailable,
              availabilityDate: new Date(
                Date.parse(data.availabilities.availabilityDate)
              )
            })
          }

          if (data.bio) setBio(data.bio)

          if (data.bio) {
            setBio(data.bio)
            dataCollected['bio'] = data.bio
          }
          if (data.photo) {
            setPhoto(data.photo)
            dataCollected['photo'] = data.photo
          }

          if (data.social) {
            if (data.social.linkedin) {
              setLinkedin(data.social.linkedin)
              dataCollected['linkedin'] = data.social.linkedin
            }
            if (data.social.github) {
              setGithub(data.social.github)
              dataCollected['github'] = data.social.github
            }
            if (data.social.website) {
              setWebsite(data.social.website)
              dataCollected['website'] = data.social.website
            }
          }

          const elementsFilled = Object.keys(dataCollected).length
          const perc = parseInt((elementsFilled / 14) * 100)
          setPercentage(perc)
        }
        setIsLoaded(true)
        setIsLoading(false)
        setIsUpdated(false)
      })
    } else {
      history.push('/login')
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  useEffect(() => {
    if (didMountRef.current) {
      if (isUpdated) {
        fetchUserData()
      }
    } else didMountRef.current = true
  })

  return (
    <>
      <div className='container'>
        <h1 className='text-align-center margin-top-5 '>
          Update your <span className='cta-highlighted-color'>profile</span> to
          get noticed by recruiters
        </h1>
      </div>
      {isLoaded && (
        <div style={{ width: 200, height: 200, margin: 'auto' }}>
          <CircularProgressbar value={percentage} text={`${percentage}%`} />
        </div>
      )}

      {showDialogUpload && !isLoading && (
        <ConfirmUploadDialog
          setDialogUploadToFalse={setDialogUploadToFalse}
          handleErrorSize={handleErrorSize}
          removeErrorSize={removeErrorSize}
          handleLoadingUpload={handleLoadingUpload}
          photo={photo}
          fileUploaded={file}
          setSuccessUpload={setSuccessUpload}
        />
      )}

      {isLoading ? (
        <div className='flex-center'>
          <div className='reverse-spinner'></div>
        </div>
      ) : (
        <div className='section short wf-section'>
          <div className='container-medium'>
            <div className='w-layout-grid  justify-center'>
              <div
                id='w-node-bb2c87d9-0030-2b78-7e8f-85f48d248aaf-0a0cf2d8'
                data-w-id='bb2c87d9-0030-2b78-7e8f-85f48d248aaf'
                style={{ opacity: 1 }}
                className='form-container'
              >
                <div className='form-block w-form'>
                  <form
                    id='email-form-2'
                    name='email-form-2'
                    data-name='Email Form 2'
                    onSubmit={onSubmit}
                  >
                    <div>
                      <h3 className='margin-top-2'>Upload picture</h3>
                      <p className='mt-1 text-sm leading-5 text-gray-500'>
                        Please add a professional picture
                      </p>

                      {!isImageLoaded && (
                        <div className='flex-center'>
                          <div className='reverse-spinner'></div>
                        </div>
                      )}
                      <div
                        className={
                          isImageLoaded
                            ? 'upload-photo-container'
                            : 'upload-photo-hidden-input'
                        }
                      >
                        <img
                          id='image'
                          className='profile-picture'
                          onLoad={handleImageLoaded}
                          src={
                            photo
                              ? photo.url
                              : 'https://yoshi-projects.s3.eu-west-3.amazonaws.com/Blk-makers/Assets/empty-profile.jpeg'
                          }
                          alt='profile pic'
                        />

                        <label
                          htmlFor='fileInput'
                          type='button'
                          className='button-primary button-header-primary w-button margin-top-1'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='upload-photo-icon'
                            viewBox='0 0 24 24'
                            strokeWidth='2'
                            stroke='currentColor'
                            fill='none'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          >
                            <rect
                              x='0'
                              y='0'
                              width='24'
                              height='24'
                              stroke='none'
                            ></rect>
                            <path d='M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2'></path>
                            <circle cx='12' cy='13' r='3'></circle>
                          </svg>
                          Upload Photo
                        </label>
                        {errorSize && (
                          <h4 className='error__wrong text-center my-4'>
                            😥 {errorSize}
                          </h4>
                        )}

                        <input
                          name='photo'
                          id='fileInput'
                          accept='image/*'
                          className='upload-photo-hidden-input'
                          type='file'
                          onChange={onChange}
                          onClick={event => {
                            event.target.value = null
                          }}
                        />
                      </div>
                    </div>{' '}
                    <div className='w-layout-grid grid-form'>
                      <div className=' margin-top-1'>
                        <label htmlFor='email'>First name</label>
                        <input
                          type='text'
                          className='input w-input'
                          placeholder='First Name'
                          name='firstName'
                          value={firstName}
                          onChange={e => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className='margin-top-1'>
                        <label htmlFor='email'>Last name</label>
                        <input
                          type='text'
                          className='input w-input'
                          placeholder='Last Name'
                          name='lastName'
                          value={lastName}
                          onChange={e => setLastName(e.target.value)}
                        />
                      </div>

                      <div
                        id='w-node-bb2c87d9-0030-2b78-7e8f-85f48d248adc-0a0cf2d8'
                        className='input-container-full-width'
                      >
                        <label htmlFor='Skills'>
                          Bio<span className='color-red'>*</span>
                        </label>
                        <textarea
                          rows='4'
                          type='text'
                          className='bio-text'
                          placeholder='Share something about yourself'
                          name='bio'
                          value={bio}
                          onChange={e => setBio(e.target.value)}
                        />
                        <div className='help-text'>
                          Let recruiters know who you are
                        </div>
                      </div>
                    </div>
                    <h3 className='margin-top-2'>Availability</h3>
                    <div>
                      <div>
                        <p className='mt-1 text-sm leading-5 text-gray-500'>
                          Let recruiters know when you are available
                        </p>
                      </div>
                      <div className='flex-wrap'>
                        {availabilityAnswers.sort().map((answer, i) => (
                          <div
                            className='margin-right-2 margin-top-1'
                            onClick={handleAvailabilities}
                            key={i}
                          >
                            <label className='w-checkbox'>
                              <div
                                className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${availabilitySelected.includes(
                                  answer
                                ) && 'w--redirected-checked'} `}
                              />
                              <input
                                type='checkbox'
                                value={answer}
                                name={answer}
                                checked={availabilitySelected.includes(answer)}
                                style={{
                                  opacity: 0,
                                  position: 'absolute',
                                  zIndex: -1
                                }}
                              />
                              <span
                                htmlFor='Show Salary'
                                className='check-label w-form-label'
                              >
                                {answer}
                              </span>
                            </label>
                          </div>
                        ))}
                        {availabilitySelected.includes('Available later') && (
                          <div className='margin-top-1'>
                            <TextField
                              id='date'
                              label='Available for work from'
                              type='date'
                              name='availabilityDate'
                              value={
                                availabilities &&
                                availabilities.availabilityDate
                                  ? convertDateToYYYYMMDD(
                                      availabilities.availabilityDate
                                    )
                                  : convertDateToYYYYMMDD(Date.now())
                              }
                              onChange={e =>
                                setAvailabilities({
                                  isAvailable: 'Available later',
                                  availabilityDate: new Date(e.target.value)
                                })
                              }
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <hr className='divider-solid' />
                    <h3 className='margin-top-2'>Experience</h3>
                    <div>
                      <div>
                        <p className='mt-1 text-sm leading-5 text-gray-500'>
                          Let recruiters know how experienced you are
                        </p>
                      </div>

                      <div className='flex-wrap'>
                        {experienceAnswers.sort().map((answer, i) => (
                          <div
                            className='margin-right-2 margin-top-1'
                            onClick={handleExperience}
                            key={i}
                          >
                            <label className='w-checkbox'>
                              <div
                                className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${experienceSelected.includes(
                                  answer
                                ) && 'w--redirected-checked'} `}
                              />
                              <input
                                type='checkbox'
                                value={answer}
                                name={answer}
                                checked={experienceSelected.includes(answer)}
                                style={{
                                  opacity: 0,
                                  position: 'absolute',
                                  zIndex: -1
                                }}
                              />
                              <span
                                htmlFor='Show Salary'
                                className='check-label w-form-label'
                              >
                                {answer}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <h3 className='margin-top-2'>Salary</h3>
                    <div>
                      <div>
                        <p className='mt-1 text-sm leading-5 text-gray-500'>
                          Current and expected salary
                        </p>
                      </div>
                      <div className='salary-box'>
                        <div className='margin-top-1'>
                          <h6>What is your current yearly salary?</h6>
                          <div className='flex-align-center-row'>
                            <span className='margin-right-0-5'>USD</span>

                            <input
                              type='text'
                              className='input-salary margin-right-0-5'
                              placeholder='100'
                              name='currentSalary'
                              value={currentSalary}
                              onChange={e => setCurrentSalary(e.target.value)}
                            />
                            <span>,000</span>
                          </div>
                          <p className='margin-top-1 bold-text'>
                            Enter amount in thousands
                          </p>
                        </div>
                        <div className='margin-top-1'>
                          <h6>What is your expected yearly salary?</h6>
                          <div className='flex-align-center-row'>
                            <span className='margin-right-0-5'>USD</span>

                            <input
                              type='text'
                              className='input-salary margin-right-0-5'
                              placeholder='100'
                              name='expectedSalary'
                              value={expectedSalary}
                              onChange={e => setExpectedSalary(e.target.value)}
                            />
                            <span>,000</span>
                          </div>
                          <p className='margin-top-1 bold-text'>
                            Enter amount in thousands
                          </p>
                        </div>
                      </div>
                    </div>
                    <h3 className='margin-top-2'>Social media</h3>
                    <div>
                      <div>
                        <p className='mt-1 text-sm leading-5 text-gray-500'>
                          Add your social media
                        </p>
                      </div>
                      <div className='flex-align-center-row'>
                        <svg
                          className='social-media-icon'
                          viewBox='0 0 512 512'
                        >
                          <path d='M186.4 142.4c0 19-15.3 34.5-34.2 34.5 -18.9 0-34.2-15.4-34.2-34.5 0-19 15.3-34.5 34.2-34.5C171.1 107.9 186.4 123.4 186.4 142.4zM181.4 201.3h-57.8V388.1h57.8V201.3zM273.8 201.3h-55.4V388.1h55.4c0 0 0-69.3 0-98 0-26.3 12.1-41.9 35.2-41.9 21.3 0 31.5 15 31.5 41.9 0 26.9 0 98 0 98h57.5c0 0 0-68.2 0-118.3 0-50-28.3-74.2-68-74.2 -39.6 0-56.3 30.9-56.3 30.9v-25.2H273.8z'></path>
                        </svg>{' '}
                        <input
                          type='text'
                          className='input w-input margin-bottom-1'
                          placeholder='LinkedIn url'
                          name='linkedin'
                          value={linkedin}
                          onChange={e => setLinkedin(e.target.value)}
                        />
                      </div>
                      <div className='flex-align-center-row'>
                        <svg
                          className='social-media-icon'
                          viewBox='0 0 512 512'
                        >
                          <path d='M256 70.7c-102.6 0-185.9 83.2-185.9 185.9 0 82.1 53.3 151.8 127.1 176.4 9.3 1.7 12.3-4 12.3-8.9V389.4c-51.7 11.3-62.5-21.9-62.5-21.9 -8.4-21.5-20.6-27.2-20.6-27.2 -16.9-11.5 1.3-11.3 1.3-11.3 18.7 1.3 28.5 19.2 28.5 19.2 16.6 28.4 43.5 20.2 54.1 15.4 1.7-12 6.5-20.2 11.8-24.9 -41.3-4.7-84.7-20.6-84.7-91.9 0-20.3 7.3-36.9 19.2-49.9 -1.9-4.7-8.3-23.6 1.8-49.2 0 0 15.6-5 51.1 19.1 14.8-4.1 30.7-6.2 46.5-6.3 15.8 0.1 31.7 2.1 46.6 6.3 35.5-24 51.1-19.1 51.1-19.1 10.1 25.6 3.8 44.5 1.8 49.2 11.9 13 19.1 29.6 19.1 49.9 0 71.4-43.5 87.1-84.9 91.7 6.7 5.8 12.8 17.1 12.8 34.4 0 24.9 0 44.9 0 51 0 4.9 3 10.7 12.4 8.9 73.8-24.6 127-94.3 127-176.4C441.9 153.9 358.6 70.7 256 70.7z'></path>
                        </svg>
                        <input
                          type='text'
                          className='input w-input margin-bottom-1'
                          placeholder='Github url'
                          name='github'
                          value={github}
                          onChange={e => setGithub(e.target.value)}
                        />
                      </div>
                      <div className='flex-align-center-row'>
                        <svg
                          className='social-media-icon'
                          fill='none'
                          stroke='black'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9'
                          ></path>
                        </svg>
                        <input
                          type='text'
                          className='input w-input margin-bottom-1'
                          placeholder='Your website url'
                          name='website'
                          value={website}
                          onChange={e => setWebsite(e.target.value)}
                        />
                      </div>
                    </div>
                    <hr className='divider-solid' />
                    <h3 className='margin-top-2'>Where do you live?</h3>
                    <div>
                      <label htmlFor='country'>Country</label>
                      <div>
                        <select
                          className='input w-input'
                          name='country'
                          value={countrySelected}
                          onChange={e => selectCountry(e.target.value)}
                        >
                          {countries.map((country, i) => (
                            <option key={i} value={country}>
                              {country}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <hr className='divider-solid' />
                    <h3 className='margin-top-2'>What are your top skills?</h3>
                    <div>
                      <div>
                        <p className='mt-1 text-sm leading-5 text-gray-500'>
                          You can select multiple skills.
                        </p>
                      </div>
                      <div className='flex-wrap '>
                        {skills.sort().map((skill, i) => (
                          <div
                            className='margin-right-2 margin-top-1'
                            onClick={handleChangeSkills}
                            key={i}
                          >
                            <label className='w-checkbox'>
                              <div
                                className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${skillsAdded.includes(
                                  skill
                                ) && 'w--redirected-checked'} `}
                              />
                              <input
                                type='checkbox'
                                value={skill}
                                name={skill}
                                checked={skillsAdded.includes(skill)}
                                style={{
                                  opacity: 0,
                                  position: 'absolute',
                                  zIndex: -1
                                }}
                              />
                              <span
                                htmlFor='Show Salary'
                                className='check-label w-form-label'
                              >
                                {skill}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <hr className='divider-solid' />
                    <h3 className='margin-top-2'>
                      What languages do you speak?
                    </h3>
                    <div>
                      <div>
                        <p className='mt-1 text-sm leading-5 text-gray-500'>
                          You can select multiple languages.
                        </p>
                      </div>
                      <div className='flex-wrap '>
                        {languagesSpoken.sort().map((language, i) => (
                          <div
                            className='margin-right-2 margin-top-1'
                            onClick={handleChangeLanguages}
                            key={i}
                          >
                            <label className='w-checkbox'>
                              <div
                                className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${languagesAdded.includes(
                                  language
                                ) && 'w--redirected-checked'} `}
                              />
                              <input
                                type='checkbox'
                                value={language}
                                name={language}
                                checked={languagesAdded.includes(language)}
                                style={{
                                  opacity: 0,
                                  position: 'absolute',
                                  zIndex: -1
                                }}
                              />
                              <span
                                htmlFor='Show Salary'
                                className='check-label w-form-label'
                              >
                                {language}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='flex-center margin-top-1'>
                      <button
                        type='submit'
                        className='button-primary large w-button'
                      >
                        Update profile
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  auth: state.authentication
})

export default connect(mapStateToProps, {})(ManageNewsletter)
