import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import PrintObject from './PrintObject'
//import * as config from "../../config";
//import { GENERATE_PAYMENT_CANDIDATE } from "../../config";
import { verifyPayment, createPostOfferOneOffPaid } from '../../utils/api'
import { LANDING_URL } from '../../utils/url'
import LockIcon from '@material-ui/icons/Lock'

import { connect } from 'react-redux'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useHistory } from 'react-router-dom'

const prod = process.env.NODE_ENV === 'production'

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#6772e5',
      color: '#6772e5',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883'
      },
      '::placeholder': {
        color: '#6772e5'
      }
    },
    invalid: {
      iconColor: '#ef2961',
      color: '#ef2961'
    }
  }
}

const ElementsForm = ({ handleSuccessMessage, ...props }) => {
  const [input, setInput] = useState({
    cardholderName: ''
  })
  const [payment, setPayment] = useState({ status: 'initial' })
  const [paymentStarted, setPaymentStarted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [cardCompleted, setCardCompleted] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const history = useHistory()

  const { auth, postData, priceChosen } = props

  const detailsPlan = priceChosen.details[0]
  const currentPrice = detailsPlan.unit_amount / 100
  const priceIdPlan = detailsPlan.id

  const user = auth.user

  const PaymentStatus = ({ status }) => {
    switch (status) {
      case 'processing':
      case 'requires_payment_method':
      case 'requires_confirmation':
        return <h2>Processing...</h2>

      case 'requires_action':
        return <h2>Authenticating...</h2>

      case 'succeeded':
        return <h2>Payment Succeeded 🥳</h2>

      case 'error':
        return (
          <>
            <h2>Error 😭</h2>
            <p className='error-message'>{errorMessage}</p>
          </>
        )

      default:
        return null
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    // Abort if form isn't valid
    const priceId = priceIdPlan //plan.priceId;

    setPaymentStarted(true)

    const data = {
      priceId: priceId
    }

    const response = await verifyPayment(data)

    const clientSecret = response.clientSecret

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)

    // Use your card Element with other Stripe.js APIs
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: { email: user.email }
        }
      }
    )

    console.log(paymentIntent)
    console.log(error)

    if (error) {
      setPayment({ status: 'error' })
      setErrorMessage(error.message)
      setPaymentStarted(false)
    } else if (paymentIntent) {
      const paymentIntentId = paymentIntent.id

      postData['paymentIntentId'] = paymentIntentId
      postData['planDetails'] = detailsPlan

      createPostOfferOneOffPaid(postData).then(res => {
        if (res.success) {
          setPaymentStarted(false)
          setSuccess(true)
          window.location.href = LANDING_URL
          handleSuccessMessage(res.message)
        } else {
          //setErrorMessage("Oops something went wrong!");
          setErrorMessage(res.message)
          setPaymentStarted(false)
        }
      })
    }
  }

  const styles = {
    bgColor: {
      backgroundColor: '#1360ef',
      color: '#fff'
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='checkout-paymentBox'>
          {success && <p className='text-align-center'>{successMessage}</p>}

          {!success && (
            <>
              <div
                style={{ padding: '0.8rem' }}
                className='FormRow elements-style'
              >
                <CardElement
                  options={CARD_OPTIONS}
                  onChange={e => {
                    if (e.complete) {
                      setCardCompleted(true)
                    }

                    if (e.error) {
                      setPayment({ status: 'error' })
                      setCardCompleted(false)
                    }
                  }}
                  hidePostalCode={true}
                />
              </div>
            </>
          )}
        </div>
        <div className='stripe-box'>
          <div className='cards-box'>
            <i className='payment-text fa fa-cc-visa'></i>
            <i className='payment-text fa fa-cc-mastercard'></i>
            <i className='payment-text fa fa-cc-amex'></i>
          </div>
          <a href='https://stripe.com/' rel='noreferrer' target='_blank'>
            <img
              alt='Stripe'
              src='https://yoshi-projects.s3.eu-west-3.amazonaws.com/Datayoshi/Assets/stripe.svg'
              className='stripe-logo'
            />
          </a>
          <div className='lock-box'>
            <LockIcon />
          </div>
        </div>

        {errorMessage && !success && (
          <div className='error__box'>
            <p className='error__wrong'>{errorMessage}</p>
          </div>
        )}
        {!success && (
          <>
            {paymentStarted ? (
              <div className='flex-center margin-top-1'>
                <div className='lds-default'>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <div class='flex-center margin-top-1'>
                <button
                  disabled={
                    !['initial', 'succeeded', 'error'].includes(
                      payment.status
                    ) || !stripe
                  }
                  type='submit'
                  class='button-primary large full-width w-button'
                >
                  {' '}
                  {errorMessage
                    ? `Retry now - $${currentPrice}`
                    : `Pay - $${currentPrice} `}
                </button>
              </div>
            )}
          </>
        )}
      </form>

      {prod ? null : <PaymentStatus status={payment.status} />}
      {prod ? null : <PrintObject content={payment} />}
    </>
  )
}
const mapStateToProps = state => ({
  auth: state.authentication
})

export default connect(mapStateToProps, {})(ElementsForm)
