import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { cancelSubscriptionCandidate } from "../../utils/api";

import { connect } from "react-redux";

const ConfirmCancellation = ({
  setDialogUploadToFalse,
  handleCancellationMessage,
  typeCancellation,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    setDialogUploadToFalse();
  };

  const cancelSubscriptionUser = () => {
    cancelSubscriptionCandidate().then((res) => {
      handleCancellationMessage(res.message);
      handleClose();
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Cancel {typeCancellation}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will now cancel your {typeCancellation}. Are you sure you want
            to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={cancelSubscriptionUser} color="primary" autoFocus>
            Cancel my {typeCancellation}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authentication,
  profile: state.userProfile,
});

export default connect(mapStateToProps, {})(ConfirmCancellation);
