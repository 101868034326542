import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { BASE_URL } from "../utils/url";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { setCurrentUser } from "../redux/actions/authActions";
import store from "../store";
const prod = process.env.NODE_ENV === "production";

const cookies = new Cookies();

function Authenticating({ ...props }) {
  const history = useHistory();

  useEffect(() => {
    const authenticateUser = () => {
      const { auth } = props;

      const isAuthenticated = auth.isAuthenticated;

      if (!isAuthenticated) {
        const url = BASE_URL + "auth/google/login/success";

        axios
          .get(
            url,
            { withCredentials: true },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true,
              },
            }
          )
          .then((res) => {
            if (res.data.success) {
              const token = res.data.token;

              if (prod) {
                cookies.set("sj_tkn_au", token, {
                  path: "/",
                  domain: ".securityjobslist.co",
                });
              } else {
                cookies.set("sj_tkn_au", token, { path: "/" });
              }

              setAuthToken(token);
              // Decode token and get user info and exp
              const decoded = jwt_decode(token);
              // Set user and isAuthenticated
              store.dispatch(setCurrentUser(decoded));

              history.push("/start");
            } else {
              history.push("/login");
            }
          })
          .catch(() => {
            history.push("/login");
          });
      } else {
        history.push("/start");
      }
    };
    authenticateUser();
  }, []);

  return <></>;
}

const mapStateToProps = (state) => ({
  auth: state.authentication,
});

export default connect(mapStateToProps, {})(Authenticating);
