import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { recoverPasswordEmail } from '../../redux/actions/authActions'

import { useHistory } from 'react-router-dom'

import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const SignUp = ({ ...props }) => {
  const history = useHistory()

  const [message, setMessage] = useState(null)
  const [messageError, setMessageError] = useState(null)
  const [error, setError] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({})

  const onSubmit = data => {
    recoverPasswordEmail(data)
      .then(res => {
        console.log(res)
        if (res.success) {
          setMessage(res.message)
        } else {
          setMessageError(res.message)
          setError(true)
        }
      })
      .catch(err => {
        setMessageError(err.response.data.message)
        setError(true)
      })
  }

  useEffect(() => {
    const { auth } = props

    const isAuthenticated = auth.isAuthenticated
    const user = auth.user

    if (isAuthenticated) {
      history.push('/start')
    }
  }, [])

  return (
    <>
      <div className='utility-page-wrap'>
        <div
          data-w-id='5e86ada79942c1e4247fd4c700000000000b'
          style={{
            transform:
              'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            opacity: 1,
            transformStyle: 'preserve-3d'
          }}
          className='utility-page-content-password w-password-page w-form'
        >
          <div className='container-default'>
            <div className=''>
              <img
                src='https://assets.website-files.com/5ec5d86528da2f24250d634c/5ecda3ef4028e0abe32b01b8_icon-password-jobs-template.svg'
                alt=''
                className='icon-password'
              />
            </div>
            <h2> Forgotten your password?</h2>

            {error && !message && (
              <h4 className='error__wrong text-align-center'>
                😥 {messageError}
              </h4>
            )}
          </div>
          {message ? (
            <h3>{message}</h3>
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='utility-page-form w-password-page'
            >
              <input
                type='email'
                autofocus='true'
                placeholder='Enter your email'
                {...register('email', {
                  required: 'Please enter your e-mail address',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Enter a valid e-mail address'
                  }
                })}
                className='input password w-password-page w-input'
              />
              {errors.email && (
                <h3 className='error__invalid'>{errors.email.message}</h3>
              )}

              <button
                type='submit'
                className='button-primary full-width w-password-page w-button'
              >
                Recover password
              </button>
            </form>
          )}

          <Link to='/login' variant='body2'>
            <div className='margin-top-2'>Already have an account? Sign in</div>
          </Link>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = state => ({
  auth: state.authentication
})

export default connect(mapStateToProps, {})(SignUp)
